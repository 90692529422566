<template>
  <div class="relative h-full w-screen bg-gp_black-default-500 flex flex-col align-center items-center">
    <div ref="background"
      class="absolute z-1 inset-0 bg-cover bg-bottom bg-fixed opacity-80"
      :style="{ backgroundImage: `url(${background})` }" 
    />
    <div class="pt-24 z-40 flex flex-col items-center justify-center w-auto h-auto">
      <img id="desktop_logo" class="h-28 w-auto mb-4" :src="logo">
      <span v-show="form.step != 8 && this.from_add_venue_in_platform !== 'true'" class="text-white text-md font-normal mt-4">Welcome to GigPig. Let's get started.</span>
      <span v-show="form.step != 8 && this.from_add_venue_in_platform === 'true'" class="text-white text-md font-normal mt-4">Add a new venue.</span>
      <span v-show="!isLoaded" class="text-gp_pink-default-400 text-xl font-bold py-44">Loading...</span>

      <ProgressBar v-show="form.step !== 13 && isLoaded" class="fade-in mt-12" :step="form.step"/>

      <div>
        <!-- Step 0 -->
        <div v-show="form.step == 0 && isLoaded" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Personal Information</span>
          <span class="text-white text-md font-normal">Add your full name</span>
          <div class="pt-4">
            <input type="text" 
                  v-model="user.first_name"
                  id="venue_name" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  placeholder="Enter your first name" 
                  required 
            />
          </div>
          <div class="pt-2">
            <input type="text" 
                  v-model="user.surname"
                  id="venue_name" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  placeholder="Enter your surname" 
                  required 
            />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div class="flex invisible items-center space-x-2">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepZero()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>
      
        <!-- Step 1 -->
        <div v-show="form.step == 1 && isLoaded" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Venue Name</span>
          <span class="text-white text-md font-normal">Add your venue name</span>
          <div class="pt-4">
            <input type="text" 
                  v-model="form.name"
                  id="venue_name" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  placeholder="Enter your venue name" 
                  required 
            />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepOne()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>
      
        <!-- Step 2 -->
        <div v-show="form.step == 2  && isLoaded" class="fade-in w-[500px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Venue Branding</span>
          <span class="text-white text-md font-normal">Upload your logo or venue image</span>
          <div class="pt-6 cursor-pointer text-gp_pink-default-400">
              <div class="input-group mt-1 sm:mt-0 sm:col-span-2">
                <profile-picture-upload 
                  ref="picuploader"
                  :image="form.icon" 
                  @onImageChange="profilePictureUploaded" 
                  profile-type="VenueProfilePicture"
                />
              </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepTwoSkip()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Skip</span>
              </div>

              <div @click="stepTwo()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 3 -->
        <div v-show="form.step == 3" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Venue Location</span>
          <span v-show="!locationName" class="text-white text-md font-normal">Where is your venue located</span>
          <span v-show="locationName" class="text-white text-md font-normal">Is this the correct location?</span>

          <div v-show="!locationName" @click="openLocationModal()" class="pt-6 cursor-pointer text-gp_pink-default-400 hover:text-gp_pink-default-500">
              <PlusIcon class="h-12 w-12 p-3 border border-dashed rounded-full"/>
          </div>
          <div v-show="locationName" class="pt-6 flex items-center space-x-2">
            <strong class="text-xl text-white">{{ locationName }}</strong>
            <XCircleIcon @click="removeLocation()" class="cursor-pointer text-gp_pink-default-500 h-4 w-4" />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepThree()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 4 -->
        <div v-show="form.step == 4" class="fade-in w-[420px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Venue Details</span>
          <span class="text-white text-md font-normal">Venue address and contact details</span>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Line 1</span>
            <input type="text" 
                  v-model="form.address.address_line_1"
                  id="line_1" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Line 2 (Optional)</span>
            <input type="text" 
                  v-model="form.address.address_line_2"
                  id="line_2" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Town / city</span>
            <input type="text" 
                  v-model="form.address.town"
                  id="town" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">County</span>
            <input type="text" 
                  v-model="form.address.county"
                  id="county" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Postcode</span>
            <input type="text" 
                  v-model="form.address.post_code"
                  id="post_code" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Email</span>
            <input type="text" 
                  v-model="form.email"
                  id="email" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="pt-4 space-y-1 text-sm font-semibold">
            <span class="text-gp_pink-default-500">Telephone</span>
            <input type="text" 
                  v-model="form.telephone"
                  id="telephone" 
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                  required 
            />
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepFour()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>

        <!-- Step 5 - Acocunt size -->
        <div v-show="form.step == 5" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Account Information</span>
          <span class="text-white text-md font-normal">How many venues do you manage?</span>
          <div class="pt-4 w-32">
            <div class="w-full py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700" data-hs-input-number="">
                <div class="flex justify-between items-center gap-x-5">
                    <div class="grow">
                        <input 
                            v-model="form.acc_size"
                            class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0" 
                            style="appearance: textfield; -moz-appearance: textfield; -webkit-appearance: textfield; position: relative;"
                            type="number" 
                            aria-roledescription="Number field" 
                            min="0"
                            value="1" 
                            data-hs-input-number-input=""
                        >
                    </div>
                </div>
            </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepFive()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
            </div>
        </div>


        <!-- Step 6 - Part of a brand? - Medium and Large acc sizes -->
        <div v-show="form.step == 6" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center text-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Company Information</span>
          <span v-show="!show_create_brand" class="text-white text-md font-normal">Are you part of one of these companies?</span>
          <span v-show="show_create_brand" class="text-white text-md font-normal">Create a company</span>

          <div v-show="!show_create_brand" class="w-full py-8">
            <TRichSelect
                    id="countries"
                    name="countries"
                    v-model="form.brand_id"
                    :options="brands"
                    value-attribute="id"
                    text-attribute="name"
                    class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
            />
            <div class="pt-8">
              <span @click="show_create_brand = !show_create_brand;" class="cursor-pointer text-gray-400 hover:text-gp_pink-default-500 text-sm">Create a new company</span>
            </div>
          </div>

          <div v-show="show_create_brand" class="w-full py-8">
            <div class="pt-4 space-y-1 font-semibold">
              <span class="text-gp_pink-default-500">Company name</span>
              <input type="text" 
                    v-model="company_name"
                    id="telephone" 
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-72 p-2.5" 
                    required 
              />
            </div>
            <div class="pt-8">
              <span @click="show_create_brand = !show_create_brand;" class="cursor-pointer text-gray-400 hover:text-gp_pink-default-500 text-sm">Choose from registered companies</span>
            </div>
          </div>
         
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepSix()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Skip</span>
              </div>
              <div @click="stepSix()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 7 - Acocunt size 2-9 - Pay via department  -->
        <div v-show="form.step == 7" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Account Information</span>
          <div class="flex items-center justify-center h-full w-44">
            <span class="text-white text-md font-normal">Pay the artists fees by Accounts Department?</span>
          </div>
          <div class="pt-4 w-full">
            <div class="flex items-center justify-center space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                <t-toggle v-model="form.pay_via_venue_accs_department"/>
            </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="updateVenue()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 8 - Acocunt size 2-9 - Head office billing details  -->
        <div v-show="form.step == 8" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Head office billing address</span>

          <div class="w-full">
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing address line one</span>
                <input type="text" 
                        v-model="form.billing_address.address_line_1"
                        placeholder="Address line one"
                        id="billing_address_line_1" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing address line two (Optional)</span>
                <input type="text" 
                        v-model="form.billing_address.address_line_2"
                        placeholder="Address line two"
                        id="billing_address_line_2" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing address town</span>
                <input type="text" 
                        v-model="form.billing_address.town"
                        placeholder="Address city"
                        id="billing_address_town" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing address county</span>
                <input type="text" 
                        v-model="form.billing_address.county"
                        placeholder="Address county"
                        id="billing_address_town" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm">
                <span class="text-gray-100 text-sm font-light">Billing address postcode</span>
                <input type="text" 
                        v-model="form.billing_address.post_code"
                        placeholder="Address postcode"
                        id="billing_address_post_code" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold w-full">
                <span class="text-gray-100 text-sm font-light">Billing address country</span>
                <TRichSelect
                    id="countries"
                    name="countries"
                    v-model="form.billing_address.country_id"
                    :options="countries"
                    value-attribute="id"
                    text-attribute="name"
                    class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing main contact name</span>
                <input type="text" 
                        v-model="form.billing_main_contact_name"
                        placeholder="e.g. John Smith"
                        id="billing_address_county" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing email address</span>
                <input type="text" 
                        v-model="form.billing_email"
                        placeholder="e.g. venue@accounts.com"
                        id="billing_address_county" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
            </div>
            <div class="pt-4 space-y-1 text-sm font-semibold">
              <span class="text-gray-100 text-sm font-light">Billing telephone</span>
              <input type="text" 
                    v-model="form.billing_telephone"
                    id="telephone" 
                    placeholder="e.g. 070000000"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                    required 
              />
            </div>
          </div>

          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepEight()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 9 - Acocunt size 2-9 - Talk with opus  -->
        <div v-show="form.step == 9" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Account Information</span>
          <div class="flex items-center justify-center h-full w-full">
            <span class="text-white text-md font-normal">Would you be interested in talking to our  Payment House partner, Opus.</span>
          </div>
          <div class="pt-4 w-full">
            <div class="flex items-center justify-center space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                <t-toggle v-model="form.talk_with_opus"/>
            </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="updateVenue()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 10 - Acocunt size 1 - billing details  -->
        <div v-show="form.step == 10" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Billing address</span>

          <div class="w-full mt-6">
            <div class="flex items-center justify-center space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                <span>Same as venue address</span>
                <t-toggle @click="copyAddress()"/>
            </div>
            <div v-show="!same_as_address">
              <div class="mt-2 pt-4 space-y-1 text-sm font-semibold">
                  <span class="text-gray-100 text-sm font-light">Billing address line one</span>
                  <input type="text" 
                          v-model="form.billing_address.address_line_1"
                          placeholder="Address line one"
                          id="billing_address_line_1" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                          required 
                  />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                  <span class="text-gray-100 text-sm font-light">Billing address line two (Optional)</span>
                  <input type="text" 
                          v-model="form.billing_address.address_line_2"
                          placeholder="Address line two"
                          id="billing_address_line_2" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                  />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                  <span class="text-gray-100 text-sm font-light">Billing address town</span>
                  <input type="text" 
                          v-model="form.billing_address.town"
                          placeholder="Address city"
                          id="billing_address_town" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                          required 
                  />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                  <span class="text-gray-100 text-sm font-light">Billing address county</span>
                  <input type="text" 
                          v-model="form.billing_address.county"
                          placeholder="Address county"
                          id="billing_address_town" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                          required 
                  />
              </div>
              <div class="pt-4 space-y-1 text-sm">
                  <span class="text-gray-100 text-sm font-light">Billing address postcode</span>
                  <input type="text" 
                          v-model="form.billing_address.post_code"
                          placeholder="Address postcode"
                          id="billing_address_post_code" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                          required 
                  />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold w-full">
                <span class="text-gray-100 text-sm font-light">Billing address country</span>
                <TRichSelect
                    id="countries"
                    name="countries"
                    v-model="form.billing_address.country_id"
                    :options="countries"
                    value-attribute="id"
                    text-attribute="name"
                    class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing email address</span>
                <input type="text" 
                        v-model="form.billing_email"
                        placeholder="venue@accounts.com"
                        id="billing_address_county" 
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                        required 
                />
              </div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Billing telephone</span>
                <input type="text" 
                      v-model="form.billing_telephone"
                      id="telephone" 
                      placeholder="e.g. 070000000"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                      required 
                />
              </div>
            </div>
          </div>

          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="stepTen()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

  
        <!-- Step 11 - Invoiced monthly for payg payments -->
        <div v-show="form.step == 11" class="fade-in w-[400px] mt-24 flex flex-col items-center text-center justify-center space-y-2 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">PAYG Billing Type</span>
          <div class="flex items-center justify-center h-full w-56">
            <span class="text-white text-md font-normal">I wish to be billed monthly for PAYG payments.</span>
          </div>
          <div class="pt-4 w-full">
            <div class="flex items-center justify-center space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                <t-toggle v-model="form.payg_billed_monthly"/>
            </div>
          </div>
          <div class="w-full flex justify-between pt-8">
              <div @click="goBack()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <ArrowNarrowLeftIcon class="w-4 h-4" />
                <span class="text-sm">Back</span>
              </div>
              <div @click="updateVenue()" class="flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500 cursor-pointer">
                <span class="text-sm">Next</span>
                <ArrowNarrowRightIcon class="w-4 h-4" />
              </div>
          </div>
        </div>

        <!-- Step 12 - Card Details - Small and medium venue sizes  -->
        <div v-show="form.step == 12" class="fade-in w-[400px] mt-24 flex flex-col items-center justify-center space-y-2 w-full h-full p-8 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span class="text-gp_pink-default-500 text-xl font-black">Card Information</span>
        
          <div class="mt-6 w-full">
            <div>
              <div class="pt-4 space-y-1 text-sm font-semibold">
                <span class="text-gray-100 text-sm font-light">Card nickname</span>
                  <input type="text" 
                          v-model="card_nickname"
                          placeholder="e.g. Head office card"
                          id="billing_address_line_1" 
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                          required 
                  />
              </div>
              <p class="text-xs text-gray-200 mt-1 w-64">Naming your card will help distinguish different cards used by your business.</p>
            </div>
            <div class="mt-6">
                <span class="text-gray-100 text-sm font-light">Billing receipt email</span>
                <input type="text" 
                            v-model="form.billing_email"
                            placeholder="accounts@myvenue.com"
                            id="billing_address_line_1" 
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2" 
                            required 
                    />
            </div>
            <div class="grid grid-cols-5 mt-6">
                <div class="col-span-1 mt-4">
                    <t-toggle @change="confirm_charge = !confirm_charge;"/>
                </div>
                <div class="col-span-4">
                    <span class="text-gray-200 text-xs text-justify font-light">I confirm my card will be charged by GigPig Ltd for the purpose of credit purchases by those with access to my venue.</span>
                </div>
            </div>
            <div class="mt-4">
                <span class="text-gray-200 text-xs text-justify font-light">
                    Your card details will be securely saved for future use by for your venues booking team, so they don’t need access to your card beyond this point.
                </span>
              </div>
            </div>
            <div class="w-full pt-10">
              <GPButton @click.native="addCardDetails()"
                              :disabled="isSubmitting"
                              :loading="isSubmitting"
                              color="pink"
                              class="w-full justify-between"
              >
                  <span class="font-semibold">Add card details</span>
                  <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
              </GPButton> 
            </div>
        </div>
          

        <!-- Last Step -  Onboard Complete  -->
        <div v-show="form.step == 13" class="fade-in mt-24 flex flex-col items-center justify-center space-y-4 w-full h-full p-16 bg-gp_black-default-500 bg-opacity-90 border border-gp_pink-default-400/50 rounded-lg shadow-xl">
          <span v-show="this.from_add_venue_in_platform !== 'true'" class="text-gp_pink-default-500 text-xl font-black">🐽 Welcome to GigPig! 🐽</span>
          <span v-show="this.from_add_venue_in_platform === 'true'" class="text-gp_pink-default-500 text-xl font-black">🐽 Venue created! 🐽</span>
          <span v-show="this.from_add_venue_in_platform !== 'true'" class="text-white text-md font-normal">Your venue is onboarded</span>
          <span class="text-white text-md font-normal">You can now start booking gigs</span>

          <div class="pt-6 w-full">
            <GPButton @click.native="toAddGig()"
                        color="pink"
                        class="w-full">Get Started</GPButton> 
          </div>

          <div class="relative flex pt-9 items-center w-full">
              <div class="flex-grow border-t border-gray-400"></div>
              <div class="flex-grow border-t border-gray-400"></div>
          </div>

          <div class="w-96 pt-4 text-center">
            <div class="py-6">
              <span class="text-sm text-center block text-gray-400">
                Want to enhance the look of your venue before you start booking? Click the link below and we’ll guide you through
                the process of adding more details to your venue, including a gallery, an ‘about’ section and social media tags.
              </span>
              <p @click="toVenueEdit()" class="underline my-6 text-gray-400 hover:text-gp_pink-default-500 font-semibold text-sm cursor-pointer inline-block">Add more venue details</p>
            </div>

            <div>
              <span class="text-sm text-center block text-gray-400">
                Want to know more about the cool features you have at your disposal before you start booking? 
                Click the link below to take the GigPig guided tour.
              </span>
              <p @click="toDashboard()" class="underline my-6 text-gray-400 hover:text-gp_pink-default-500 font-semibold text-sm cursor-pointer inline-block">Take the GigPig tour!</p>
            </div>
          </div> 
        </div>

      </div>

      <div class="mt-24 pb-64 flex items-center space-x-2 text-gray-400 hover:text-gp_pink-default-500">
          <UserIcon @click="logoutUser()" class="w-5 h-5 cursor-pointer" />
          <span @click="logoutUser()" class="cursor-pointer font-normal">Logout</span>
      </div>


    </div>

    <VenueLocationModal 
      ref="locationModal" 
      v-on:saveLocation="storeLocation($event)" 
      v-on:updateVenueDetails="updateVenueDetails($event)" 
      v-on:updateCountry="pickCountry($event)"
    />

    <SubmitMITPayment
        ref="paymentModal"
        :show-modal="showMITModal"
        :MITPublicKey="pubKey"
        :MITClientSecret="MITClientSecret"
        :customerEmail="ryftCustomerEmail"
        @showMITPaymentModal="value => showMITModal = value"
        @formSubmitted="formSubmitted"
        @cardSubmissionComplete="cardSubmissionComplete"
        @cardNumberIsValid="value => cardNumberIsValid = value"
    />
   </div>

</template>

<script>
import { UserIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon, PlusIcon, XCircleIcon } from "@vue-hero-icons/outline"
import NProgress from 'nprogress';
import ProfilePictureUpload from '../../components/uploads/profile-picture-upload.vue'
import VenueLocationModal from '../../components/modal/VenueLocation.vue';
import client from "../../utils/client"
import GPButton from '../../components/ui/buttons/Button.vue';
import ProgressBar from '../../components/ui/stepper/VenueOnboardProgressBar.vue';
import JSConfetti from 'js-confetti'
import { paymentsMethods, apiMethods } from "@/state/helpers.js";
import { TToggle, TRichSelect } from 'vue-tailwind/dist/components'
import { DEFAULT_CURRENCY_ID } from "@/constants/contants";
import SubmitMITPayment from "../../components/MIT/SubmitMITPayment.vue";
import { mapActions } from "vuex";

export default {
  name: "VenueOnboard",
  components: {
    UserIcon, ArrowNarrowRightIcon, ArrowNarrowLeftIcon, PlusIcon, XCircleIcon,
    ProfilePictureUpload,
    VenueLocationModal,
    GPButton,
    ProgressBar,
    TToggle, TRichSelect,
    SubmitMITPayment
  },
  data() {
    return {
      background: require('@/assets/images/concert.jpg'),
      logo: require("@/assets/images/gigpig_logo_2025.svg"),
      townPreview: '',
      countyPreview: '',
      user: {
        first_name: '',
        surname: ''
      },
      userCopy: {
        first_name: '',
        surname: ''
      },
      from_add_venue_in_platform: sessionStorage.getItem('from_add_venue'),
      card_nickname: '',
      recipient_email: '',
      form: {
        id: null,
        name: '',
        icon: null,
        step: 0,
        brand_id: null,
        address: {
          address_line_1: '',
          address_line_2: '',
          town: '',
          county: '',
          post_code: '',
        },
        billing_address: {
          address_line_1: '',
          address_line_2: '',
          town: '',
          county: '',
          post_code: '',
          country_id: null,
        },
        billing_email: '',
        billing_telephone: '',
        billing_main_contact_name: '',
        email: '',
        telephone: '',
        location: {},
        country_id: null,
        acc_size: 0,
        pay_via_venue_accs_department: false,
        payg_billed_monthly: false,
        talk_with_opus: false,
      },
      addressCopy: {
        address_line_1: '',
        address_line_2: '',
        town: '',
        county: '',
        post_code: '',
      },
      billing_addressCopy: {
          address_line_1: '',
          address_line_2: '',
          town: '',
          county: '',
          post_code: '',
          email: '',
      },
      show_create_brand: false,
      company_name: '',
      onboarded: false,
      brands: [],
      same_as_address: false,
      formCopy: null,
      isLoaded: false,
      fave: false,
      isSubmitting: false,
      cardId: null,
      ryftCustomerCreated: false,
      currencies: [],
      countries: [],
      venue: [],
      base_currency: DEFAULT_CURRENCY_ID,
      MITClientSecret: '',
      ryftCustomerEmail: null,
      showMITModal: false,
      pubKey: process.env.VUE_APP_RYFT_PUBLIC_KEY,
      cardNumberIsValid: false,
      confirm_charge: false,
    };
  },
  watch: {
    'form.icon.uploading'(newValue) {
      if (newValue === false) {
        this.stepTwo();
      }
    },
    ryftCustomerCreated() {
      this.initRyftOnboarding();
    },
  },
	computed:{
    locationName() {
      return this.form.country_id ? this.form.location.preview : ''
    },
    userData(){
			return this.$store.getters['user/userData']
		},
    currencyIsoCode() {
        return this.currencies.find(f => f.id === this.base_currency).iso_code;
    },
	},
  async mounted() {

    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    if (params.has('fave')) {
      this.fave = params.get('fave');
    }

    NProgress.start();

    await this.getCurrencies().then(response => (this.currencies = response.data));
    await this.getCountries().then(response => (this.countries = response.data));
    await this.getBrands().then(response => (this.brands = response.data))

    await client
          .get('user')
          .then((response) => {
            if (response.data.data.first_name && response.data.data.surname){


              this.user.first_name = response.data.data.first_name;
              this.user.surname = response.data.data.surname;

              this.form.step = 1;


              
              if (sessionStorage.getItem('from_add_venue') !== 'true') {
                client
                  .get('venues-onboarding')
                  .then((response) => {
                      if(response.data.data) {
                        this.form = response.data.data;
                      }
                      this.dataChecks();
                  })
                  .catch(() => {
                    this.isLoaded = true;
                  })
              } else {
                this.isLoaded = true;
              }
            } else {
              this.isLoaded = true;
            }
          })
          .finally(() => {
            this.userCopy = JSON.parse(JSON.stringify(this.user))
            this.formCopy = JSON.parse(JSON.stringify(this.form))
            NProgress.done();
          })

          if (this.form.step === 0) {     
            this.setGTMTracker(0);
          }
          
  },
  methods: {
    ...mapActions('account', ['getUserInfo']),
    ...apiMethods,
    ...paymentsMethods,
    setGTMTracker($stage){
      
      let onboardStageDescriptor = null;

      if ($stage == 0){
        onboardStageDescriptor = 'onboarding_initiated';
      } else if ($stage == 13){
        onboardStageDescriptor = 'onboarding_completed';
      }

      if (onboardStageDescriptor !== null) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({'event': onboardStageDescriptor});
      }

    },
    openLocationModal() {
      this.$refs.locationModal.isDarkMode = true;
      this.$refs.locationModal.toggleModal();
    },
    goBack() {
      if (this.form.step > 0) {
        
        this.$refs.background.scrollIntoView({behavior: 'smooth'})

        if (this.form.step == 10 && this.form.acc_size == 1) {
          this.form.step = 5;
        } else if (this.form.step == 12 && this.form.acc_size == 1) {
          this.form.step = 10;
        } else if (this.form.step == 10 && (this.form.acc_size > 1 && this.form.acc_size < 10)) {
          this.form.step = 7;
        } else if (this.form.step == 11 && (this.form.acc_size > 1 && this.form.acc_size < 10)) {
          this.form.step = 9;
        } else {
          this.form.step = this.form.step - 1;
        }

      }
    },
    async dataChecks() {

      if(this.form.email == null) {
        this.form.email = this.userData.email;
      }

      if(this.form.address == null) {
        this.form.address = JSON.parse(JSON.stringify(this.addressCopy));
      }

      if(this.form.billing_address == null) {
        this.form.billing_address = JSON.parse(JSON.stringify(this.billing_addressCopy));
      }

      if (this.townPreview !== '') {
        this.form.address.town = this.townPreview;
      }

      if (this.countyPreview !== '') {
        this.form.address.county = this.countyPreview;
      }

      if (this.form.step == 12 && this.form.acc_size < 10 && !this.onboarded) {
        await this.getUserAccInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
        this.form.step = 13;
      }

      if (this.onboarded) {
        await this.getUserAccInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
        this.loadConfetti();
        this.form.step = 13;
        this.setGTMTracker(13);
      }

      if (this.form.step == 11) {
        this.form.step = 12;
      }

      if (this.form.step == 10) {
        this.form.step = 12;
      }

      if (this.form.step == 9) {    
        if (this.form.talk_with_opus == null) {
          this.form.talk_with_opus = false;
        }
        this.form.step = 11;
      }

      if (this.form.step == 8) {
        this.form.step = 9;
      }

      if (this.form.step == 7) {

        if (this.form.pay_via_venue_accs_department === null || this.form.pay_via_venue_accs_department === false) {
            this.form.pay_via_venue_accs_department = false; // Ensures it's explicitly set to false if null
            this.form.step = 10;
        } else if (this.form.pay_via_venue_accs_department === true) {
            this.form.step = 8;
        }
      }

      if (this.form.step == 6 && (this.form.acc_size > 1 && this.form.acc_size < 10)) {
        this.form.step = 7;
      }

      if (this.form.step == 5 && this.form.acc_size > 1) {
        this.form.step = 6;
      }

      if (this.form.step == 5 && this.form.acc_size == 1) {
        this.form.step = 10;
      }

      if (this.form.step < 5) {
        this.form.step = this.form.step + 1;
      }

      if (!this.isLoaded) {
        this.isLoaded = true;
      }

    },
    async updateVenue(params = null) {

      NProgress.start();

      const requestObject = params ? params : this.form;

      if (this.form.talk_with_opus == null) {
        this.form.talk_with_opus = false;
      }

      if (this.form.pay_via_venue_accs_department === null) {
        this.form.pay_via_venue_accs_department = false;
      }

      if (this.form.payg_billed_monthly == null) {
        this.form.payg_billed_monthly = false;
      }


      await client
              .put('venues-onboarding/' + this.form.id, requestObject)
              .then((response) => {
                  
                  if(response.data.data) {
                    if (this.onboarded) {
                      this.venue = response.data.data;
                    } else {
                      this.form = response.data.data;
                    }
                  }

                  this.dataChecks();

                  this.$refs.background.scrollIntoView({behavior: 'smooth'});
              })
              .finally(() => {
                this.formCopy = JSON.parse(JSON.stringify(this.form))
                NProgress.done();
              })
    },
    async stepZero() {

      if (!this.user.first_name) {
        this.$notify("Please enter your first name.");
        return;
      } 

      if (!this.user.surname) {
        this.$notify("Please enter your surname.");
        return;
      } 

      if (this.user.first_name != this.userCopy.first_name || this.user.surname != this.userCopy.surname) {

        NProgress.start();

        await client
          .put("user/update-fullname", this.user)
          .then(() => {
            this.form.step = 1;
            NProgress.done();
          })
      } else {
        this.form.step = 1;
      }

    },
    async stepOne() {

      if (!this.form.name) {
        this.$notify("Please input a venue name.");
        return;
      } 

      if (this.form.name != this.formCopy.name) {

        if (!this.form.id) {

          NProgress.start();

          if (this.fave !== false) {
            this.form.fave = this.fave;
          }

          await client
                  .post('venues-onboarding', this.form)
                  .then((response) => {

                      if(response.data.data) {
                        this.form = response.data.data;
                      }

                      this.dataChecks();
                  })
                  .finally(() => {
                    this.formCopy = JSON.parse(JSON.stringify(this.form))
                    NProgress.done();
                  })

        } else {
          await this.updateVenue();
        }

      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 2;
      }

    },
    stepTwoSkip() {
      this.form.icon != null ? this.stepTwo() : this.$refs.picuploader.uploadDefault()
    },
    async stepTwo() {

      if (this.form.icon == null) {
        this.$notify("Please upload a venue image / logo.");
        return;
      }

      if (this.form.icon.uploading == true) {
        this.$notify("Image uploading. Please try again momentarily.");
        return;
      }

      if (JSON.stringify(this.form.icon) != JSON.stringify(this.formCopy.icon)) {
        await this.updateVenue();
      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 3;
      }

    },
    async stepThree() {

      if (this.form.location == null || this.form.country_id == null) {
        this.$notify("Please choose a venue location.");
        return;
      }

      if (JSON.stringify(this.form.location) != JSON.stringify(this.formCopy.location)) {
        await this.updateVenue();
      } else {
        this.$refs.background.scrollIntoView({behavior: 'smooth'});
        this.form.step = 4;
      }

    },
    async stepFour() {

      if (!this.form.address.address_line_1  || !this.form.address.town || !this.form.address.county || 
          !this.form.address.post_code || !this.form.telephone || !this.form.email) 
      {
        this.$notify("Please provide all requested details.");
        return;
      }

      if (!this.form.email.includes('@')) {
        this.$notify("Please provide a valid email address.");
        return;
      }

      if ((JSON.stringify(this.form.address) !== JSON.stringify(this.formCopy.address)) || this.form.telephone != this.formCopy.telephone || this.form.email != this.formCopy.email) {

        this.form.address.country_id = this.form.country_id;
        await this.updateVenue();
      } else {
        this.form.step = 5;
      }

    },
    async stepFive() {
      
      if (this.form.acc_size == 0) {
        this.$notify("Please provide a valid venue count.");
        return;
      }

      await this.updateVenue();
    },
    async stepSix() {

      // if (!this.show_create_brand && !this.form.brand_id) {
      //   this.$notify("Please choose a comapny.");
      //   return;
      // }

      // if (this.show_create_brand && !this.company_name) {
      //   this.$notify("Please provide a comapny name.");
      //   return;
      // }
      
      if (this.form.acc_size >= 10) {
        this.onboarded = true;
      }

      if (this.show_create_brand && this.company_name) {

        let payload = { 'name': this.company_name };

        this.createBrand(payload)
            .then((response) => {
              this.form.brand_id = response.data.id;
              this.updateVenue();
            })

      } else {
        this.updateVenue();
      }
    },
    async stepEight() {

      if (!this.form.billing_address.address_line_1  || !this.form.billing_address.town || !this.form.billing_address.county || 
        !this.form.billing_address.post_code || !this.form.billing_address.country_id || !this.form.billing_email || !this.form.billing_telephone || !this.form.billing_main_contact_name) 
      {
        this.$notify("Please provide all requested details.");
        return;
      }

      if (!this.form.billing_email.includes('@')) {
        this.$notify("Please provide a valid email address.");
        return;
      }

      await this.updateVenue();
    },
    async stepTen() {

      if (!this.form.billing_address.address_line_1  || !this.form.billing_address.town || !this.form.billing_address.county || 
        !this.form.billing_address.post_code || !this.form.billing_address.country_id || !this.form.billing_email || !this.form.billing_telephone) 
      {
        this.$notify("Please provide all requested details.");
        return;
      }

      if (!this.form.billing_email.includes('@')) {
        this.$notify("Please provide a valid email address.");
        return;
      }

      await this.updateVenue();
    },
    addCardDetails() {

        if (!this.card_nickname || !this.form.billing_email)
        {
            this.$notify("Please provide all requested details.");
            return;
        }

        if (!this.confirm_charge)
        {
            this.$notify("Please accept the terms of use to continue.");
            return;
        }

        this.loadRyftSdk()
    },
    formSubmitted() {
        NProgress.start();
    },
    async loadRyftSdk() {

        this.isSubmitting = true;

        await client
                  .put('venues-onboarding/' + this.form.id, this.form)
                  .then((response) => {
                    this.venue = response.data.data;
                  })
                  .finally(() => {
                      this.createPaymentCard({
                          default: 1, 
                          email: this.form.billing_email,
                          venue_id: this.venue.id,
                          alias: this.card_nickname,
                      }).then(resp => {
                          this.cardId = resp.data.id;
                          this.ryftCustomerCreated = true;
                      }).catch(() => {
                        this.isSubmitting = false;
                      })
                  })
    },
     initRyftOnboarding() {
        this.createMerchantPaymentSession(
            {
                amount: 0,
                verifyAccount: true,
                currency: this.currencyIsoCode,
                email: this.form.billing_email,
                metadata: {verifyAccount: true, default: true, cardId: this.cardId.toString(), venueId:this.venue.id },
                paymentType: 'Unscheduled',
                venue_id: this.venue.id,
                venueId:this.venue.id
            }
        ).then((resp) => {
            this.MITClientSecret = resp.data.clientSecret
            this.ryftCustomerEmail = resp.data.customerEmail
            this.showMITModal = true
            this.isSubmitting = false;
        });
    },
    cardSubmissionComplete() {
        this.$notify("Please wait while we verify your payment card information.");
        this.cardSubmissionCheck();
    },
    cardSubmissionCheck() {
        setTimeout(() => {
            this.getVenuePaymentCard({ card_id: this.cardId })
                .then((response) => {
                    if (!response.data.merchant_id || !response.data.initial_payment_id) {
                        this.cardSubmissionCheck();
                    } else {
                        this.ryftOnboardingComplete = true;
                        this.showMITModal = false;
                        NProgress.done();
                        this.onboardComplete();
                    }
                })
        }, 2000);
    },
    async onboardComplete() {

      if (sessionStorage.getItem('from_add_venue') !== 'true') {
        await this.createRyftSubscription({venue_id: this.venue.id, subscriptionPlan: 1, card_id: this.cardId })
      } else {
        sessionStorage.removeItem('from_add_venue');
      }

      await this.getUserAccInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
      this.loadConfetti();
      this.form.step = 13;
    },
    toVenueEdit() {
      this.$router.push({ name: "VenuesDetails", params: { venueId: this.venue.id } });
    },
    toAddGig() {
      this.$router.push('/gigs/add');
    },
    toDashboard() {
      this.$router.push('/dashboard');
    },
    profilePictureUploaded(image) {
			this.form.icon = image;
		},
    storeLocation($location){
      this.form.location = $location;
      this.stepThree();
    },
    removeLocation(){
      this.form.location = {};
      this.form.country_id = null;
    },
    pickCountry(value){
        this.form.country_id = value;
    },
    updateVenueDetails(value) {
      this.townPreview = value.place ? value.place : '';
      this.countyPreview = value.area ? value.area : '';
    },
    copyAddress() {
      
      this.same_as_address = !this.same_as_address;

      this.form.billing_email = this.same_as_address ? this.form.email : '';
      this.form.billing_telephone = this.same_as_address ? this.form.telephone : '';

      const address = this.same_as_address 
          ? { ...this.form.address, country_id: this.form.country_id } 
          : { address_line_1: '', address_line_2: '', town: '', county: '', post_code: '', country_id: null };

      this.form.billing_address = { ...this.form.billing_address, ...address };

    },
    loadConfetti() {
      const jsConfetti = new JSConfetti()
      jsConfetti.addConfetti({
        confettiRadius: 6,
        confettiNumber: 500,
      })

      setTimeout(function() {
        jsConfetti.addConfetti({
          confettiRadius: 6,
          confettiNumber: 500,
        });
      }, 2000);
    },
    logoutUser() {
      sessionStorage.removeItem('from_add_venue');
      this.$auth.logout()
    }
  }
};
</script>


<style>
@keyframes fadeIn {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}
</style>
